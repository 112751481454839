export const GRAPHQL_WEBSITE_HEADER = {
  context: {
    headers: {
      source: 'website',
    },
  },
};
export const RAZORPAY_CODE = 'razorpayweb';

export const EXPRESS_CHECKOUT_HEADER = {
  context: {
    headers: {
      source: 'expresscheckout',
    },
  },
};

export const OUT_STOCK_TEXT = 'Sold Out';
export const CHEKOUT_METHOD_NATIVE_EVENT_KEY = 'native';
export const CHEKOUT_METHOD_MAGIC_EVENT_KEY = 'magic';

export const TRACKING_PARAMS_LIST = [
  'gclid',
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_term',
  'utm_content',
];